/****************************************
download button
****************************************/
.download-button {
  display: inline-block;
  width: max-content;
  text-align: left;
  @include border-radius;

  &-group {
    // .download-button:not(:last-child) {
    //     margin-right: 1.8rem;
    // }
    .download-button {
      margin-left: 1.8rem;
    }
    margin-left: -1.8rem;
  }

  &-group:not(.download-button-group-1) {
    @media (max-width: 1199px) {
      // margin-right: -1.8rem;
      .download-button {
        margin-top: 1rem;
        // margin-right: 1.8rem;
      }

      margin-top: -1rem;
    }
  }

  &-inner {
    display: flex;
    align-items: center;
    padding: 1.8rem 3rem 1.6rem 3.4rem;

    background-color: transparent;

    position: relative;
    overflow: hidden;

    @include border-radius;

    &::after {
      content: '';
      display: block;

      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      background-color: #fff;

      @include animateIt(0.4s, $timing_func: ease-in-out);
    }
  }

  &-icon {
    position: relative;
    z-index: 2;
    i {
      @include animateIt($delay: 0.1s);
    }
  }

  &-content {
    position: relative;
    z-index: 2;
    margin-left: 1.2rem;

    h5 {
      font-size: 1rem;
      font-weight: 700;
      margin: 0;
      @include animateIt($delay: 0.1s);
    }
    h3 {
      font-size: 2rem;
      font-weight: 700;
      margin: 0.3rem 0 0 0;
      @include animateIt($delay: 0.1s);
    }
  }

  &-google {
    @include border-gradient(blue, $deg: 270deg);
    @include drop-shadow($color: var(--gradient-green-rgba-1), $yOffset: 10px, $blurRadius: 10px);
    .download-button-inner {
      &::after {
        background-color: var(--gradient-green);
      }
    }
    .download-button-icon {
      i {
        font-size: 3.4rem;
      }
    }
  }

  &-apple {
    @include border-gradient(blue);
    @include drop-shadow($color: var(--gradient-blue-rgba-1), $yOffset: 10px, $blurRadius: 10px);
    .download-button-inner {
      &::after {
        background-color: var(--gradient-blue);
      }
    }
    .download-button-icon {
      i {
        font-size: 4rem;
      }
    }
  }

  &:hover {
    .download-button-inner {
      &::after {
        left: 100%;
      }
    }
    .download-button-icon {
      i {
        color: #fff;
      }
    }
    .download-button-content {
      h3,
      h5 {
        color: #fff;
      }
    }
  }
  &-rotaractButton {
    @include border-gradient(blue);
    @include drop-shadow($color: var(--gradient-blue-rgba-1), $yOffset: 5px, $blurRadius: 5px);
    .download-button-inner {
      &::after {
        background-color: var(--gradient-blue);
      }
    }
    .download-button-icon {
      i {
        font-size: 4rem;
      }
    }
  }

  &:hover {
    .download-button-inner {
      &::after {
        left: 100%;
      }
    }
    .download-button-icon {
      i {
        color: #fff;
      }
    }
    .download-button-content {
      h3,
      h5 {
        color: #fff;
      }
    }
  }

  &-1 {
    padding: 0;
    background: transparent;
    -webkit-box-shadow: inset 0px 0px 0px var(--border-width) #ffffff;
    box-shadow: inset 0px 0px 0px var(--border-width) #ffffff;
    .download-button-inner {
      padding: calc(1.8rem + var(--border-width)) calc(3rem + var(--border-width))
        calc(1.6rem + var(--border-width)) calc(3.4rem + var(--border-width));

      &::after {
        left: 100%;
        background-color: #fff;
      }
    }

    .download-button-icon {
      i {
        color: #fff;
      }
    }
    .download-button-content {
      h3,
      h5 {
        color: #fff;
      }
    }

    &:hover {
      .download-button-inner::after {
        left: 0;
      }

      .download-button-icon {
        i {
          color: unset;
        }
      }
      .download-button-content {
        h3 {
          color: $font-dark;
        }
        h5 {
          color: $font-grey;
        }
      }
    }

    &:not(:last-child) {
      margin: 0 0 1rem 0 !important;
    }

    &-group {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: 100%;
    }

    @media (max-width: 991px) {
      &-group {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        margin: 0 0 0 -2rem;
      }

      &,
      &:not(:last-child) {
        margin: 0 0 0 2rem !important;
      }
    }
  }

  &-2 {
    padding: 0;
    background: transparent;
    -webkit-box-shadow: inset 0px 0px 0px var(--border-width) #ffffff;
    box-shadow: inset 0px 0px 0px var(--border-width) #ffffff;
    .download-button-inner {
      padding: calc(1.8rem + var(--border-width)) calc(3rem + var(--border-width))
        calc(1.6rem + var(--border-width)) calc(3.4rem + var(--border-width));

      &::after {
        left: 100%;
        background-color: #fff;
      }
    }

    .download-button-icon {
      i {
        color: #fff;
      }
    }
    .download-button-content {
      h3,
      h5 {
        color: #fff;
      }
    }

    &:hover {
      .download-button-inner::after {
        left: 0;
      }

      .download-button-icon {
        i {
          color: unset;
        }
      }
      .download-button-content {
        h3 {
          color: $font-dark;
        }
        h5 {
          color: $font-grey;
        }
      }
    }
  }
  &-white {
    padding: 0;
    background: white;
    -webkit-box-shadow: inset 0px 0px 0px var(--border-width) #ffffff;
    box-shadow: inset 0px 0px 0px var(--border-width) #ffffff;
    .download-button-inner {
      padding: calc(0.5rem + var(--border-width)) calc(3rem + var(--border-width))
        calc(0.2rem + var(--border-width)) calc(3.4rem + var(--border-width));

      &::after {
        left: 100%;
        background-color: #fff;
      }
    }
    margin-bottom: 1em;

    .download-button-icon {
      i {
        color: #fff;
      }
    }
    .download-button-content {
      h3,
      h5 {
        color: #fff;
      }
    }

    &:hover {
      .download-button-inner::after {
        left: 0;
      }

      .download-button-icon {
        i {
          color: unset;
        }
      }
      .download-button-content {
        h3 {
          color: $font-dark;
        }
        h5 {
          color: $font-grey;
        }
      }
    }
  }
}

/****************************************
button
****************************************/
.button {
  display: inline-block;
  width: max-content;

  background-color: #000;
  border: none;

  overflow: hidden;

  &-inner {
    display: flex;
    align-items: center;
    padding: 1.9rem 5.6rem;

    background-color: transparent;

    position: relative;
    overflow: hidden;

    @include border-radius();

    &::after {
      content: '';
      display: block;

      width: 100%;
      height: 100%;

      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      background-color: #fff;
      @include animateIt(0.4s, $timing_func: ease-in-out);
    }
  }

  &-content {
    position: relative;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 1.5rem;
      margin-right: 0.4rem;
    }

    h4,
    .text {
      font-size: 1.4rem;
      font-weight: 700;
      margin: 0.3rem 0 0 0;
      @include animateIt($delay: 0.1s);

      @media (max-width: 991px) {
        font-size: 1.6rem;
      }
    }
  }

  &:hover {
    .button-inner {
      &::after {
        left: 100%;
      }
    }

    .button-content {
      color: #fff;
    }
  }

  &-1 {
    background-color: transparent;
    -webkit-box-shadow: inset 0px 0px 0px 0.3rem #ffffff;
    box-shadow: inset 0px 0px 0px 0.3rem #ffffff;

    .button-inner {
      &::after {
        left: 100%;
      }
    }

    .button-content {
      color: #fff;
    }

    &:hover {
      .button-inner {
        &::after {
          left: 0;
        }
      }

      .button-content {
        color: var(--gradient-blue);
      }
    }
  }

  &-2 {
    color: #fff;
    @include border-gradient(green-1, $deg: 90deg);
    .button-inner {
      &::after {
        background-color: var(--gradient-green);
      }
    }
    @include drop-shadow($color: var(--gradient-green-rgba-2), $yOffset: 10px, $blurRadius: 10px);
  }

  &-3 {
    color: #fff;
    @include border-gradient(blue);
    .button-inner {
      &::after {
        background-color: var(--gradient-blue);
      }
    }
    @include drop-shadow($color: var(--gradient-blue-rgba-2), $yOffset: 10px, $blurRadius: 10px);
  }

  &-basic {
    color: #fff;
    @include border-gradient(green);
    .button-inner {
      &::after {
        background-color: var(--gradient-green);
      }
    }
    @include drop-shadow($color: var(--gradient-green-rgba-4), $yOffset: 10px, $blurRadius: 10px);
  }

  &-standard {
    color: #fff;
    @include border-gradient(green-1, $deg: 270deg);
    .button-inner {
      &::after {
        background-color: var(--gradient-green-1);
      }
    }
    @include drop-shadow($color: var(--gradient-green-rgba-4), $yOffset: 10px, $blurRadius: 10px);
  }

  &-premium {
    color: #fff;
    @include border-gradient(blue-1, 270deg);
    .button-inner {
      &::after {
        background-color: var(--gradient-blue);
      }
    }
    @include drop-shadow($color: var(--gradient-blue-rgba-4), $yOffset: 10px, $blurRadius: 10px);
  }

  @include border-radius();
}

.screen-section {
  position: relative;

  &-wrapper {
    position: relative;
    z-index: 2;

    padding-top: 15rem;
    margin-bottom: 15rem;

    @media (max-width: 991px) {
      padding-top: 5rem;
      margin-bottom: 6rem;
    }
  }

  .background-pattern {
    width: 100%;
    height: 50%;
    top: initial;
    bottom: 0;

    &-gradient {
      @include border-gradient-double(270deg);
    }
  }

  &-bottom {
    &-wrapper {
      padding: 2rem 0 15rem 0;
    }

    h4 {
      margin-top: 4rem;

      i {
        font-size: 2.4rem;
      }
    }

    @media (max-width: 991px) {
      text-align: center;

      h4 {
        margin-top: 2rem;
      }

      &-wrapper {
        padding: 0 0 6.5rem 0;
      }

      .download-button-group {
        margin-top: 2rem;
      }
    }
  }
}

.screen-slider {
  overflow: visible;
  margin-top: 9rem;

  @media (max-width: 991px) {
    margin-top: 3rem;
  }
}

.screen-slide {
  padding-bottom: 5rem;
  figure {
    @include drop-shadow($color: var(--blue-rgba-4), $yOffset: 30px, $blurRadius: 15px);
  }
  img {
    width: 100%;
  }
}

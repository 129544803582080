/****************************************
blog section
****************************************/
.blog-section {
  .blog-section-wrapper {
    padding-bottom: 15rem;

    @media (max-width: 991px) {
      padding-bottom: 5rem;
    }
  }

  .section-heading {
    margin-bottom: 4rem;
  }

  .button {
    margin: 5rem auto 0 auto;
    display: block;
  }

  @media (min-width: 992px) {
    div[class*='col'] {
      &:nth-child(n + 4) {
        margin-top: 5rem;
      }
    }
  }

  @media (max-width: 991px) {
    div[class*='col'] {
      &:nth-child(n + 2) {
        margin-top: 3rem;
      }
    }
  }

  &-1 {
    @media (max-width: 991px) {
      div[class*='col'] {
        &:nth-child(-n + 2) {
          margin-top: 0;
        }
        &:nth-child(n + 3) {
          margin-top: 3rem;
        }
      }
    }

    @media (max-width: 767px) {
      div[class*='col'] {
        &:nth-child(n + 2) {
          margin-top: 3rem;
        }
      }
    }
  }
}

/****************************************
blog single
****************************************/
.blog-single {
  @include border-radius(3rem);
  @include border-gradient-double(180deg, $first-pos: 0, $second-pos: 50%);
  @include drop-shadow($color: var(--gradient-green-rgba-1));
  background-color: #fff;

  padding: 0.4rem;
  // margin: 0 2.3rem;
  text-align: center;

  &-wrapper {
    position: relative;
    background-color: #fff;
    padding: 2.6rem 2.6rem 5.6rem 2.6rem;
    @include border-radius(2.7rem);
    // border: .1rem solid transparent;
  }

  .figure {
    position: relative;
    overflow: hidden;
    @include drop-shadow(var(--gradient-green-rgba-2));
    @include border-radius(2rem);

    display: block;
    width: 100%;
    height: 24rem;

    img {
      @include border-radius(2rem);
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .img-hover {
      position: absolute;
      top: 0;
      left: 0;

      opacity: 0;
      visibility: hidden;

      width: 100%;
      height: 100%;

      @include animateIt;

      background: var(--gradient-green-rgba-4);
      background: linear-gradient(
        90deg,
        var(--gradient-blue-rgba-4) 20%,
        var(--gradient-green-rgba-4) 80%
      );

      .icon {
        @include absoluteCenter;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        background-color: #fff;
        top: 100%;
        @include animateIt($delay: 0.1s);
      }

      i {
        @include absoluteCenter;
        font-size: 3rem;

        @include icon-gradient;
      }
    }

    &:hover,
    &.hover {
      .img-hover {
        opacity: 1;
        visibility: visible;

        .icon {
          top: 50%;
        }
      }
    }
  }

  .circle {
    position: relative;
    overflow: hidden;

    width: 3.7rem;
    height: 3.7rem;
    border-radius: 50%;
    background-color: var(--gradient-green);

    font-size: 2.4rem;
    color: #fff;

    position: absolute;
    bottom: 1rem;
    right: 1rem;
    z-index: 2;

    i {
      font-size: 2.4rem;
      color: #fff;

      @include absoluteCenter;
      @include animateIt(0.3s);
    }

    .hover {
      top: 125%;
    }

    &:hover,
    &.hover {
      .hover {
        top: 50%;
      }

      i:not(.hover) {
        top: -125%;
      }
    }

    @include animateIt(0.25s);
  }

  &-details {
    width: max-content;
    margin: 1rem auto;

    display: flex;
    align-items: center;

    font-size: 1.4rem;
    font-weight: 500;
    color: $font-grey-1;
    i {
      font-size: 1.8rem;
      color: var(--gradient-green-1);
    }
    .separator {
      width: 0.2rem;
      height: 1.2rem;
      background-color: $color-grey;

      margin: 0 1.3rem 0 1.7rem;
    }

    @media (max-width: 991px) {
      font-size: 1.6rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 3rem;
    color: $font-dark;

    margin-top: 3rem;
  }

  p {
    color: $font-grey-1;
  }

  &-1 {
    .blog-single-wrapper {
      overflow: hidden;

      .blog-single-content {
        position: relative;
        z-index: 1;
      }

      h3,
      .blog-single-details,
      .blog-single-details i,
      p {
        @include animateIt;
      }

      &::after {
        content: '';

        position: absolute;
        top: 100%;
        left: 0;

        opacity: 0;
        visibility: hidden;

        z-index: 0;

        width: 100%;
        height: 100%;

        @include border-gradient-double(-180deg, $first-pos: 0%, $second-pos: 50%);
        @include border-radius(1.5rem);
        @include animateIt;
      }
    }

    &:hover {
      .blog-single-wrapper::after {
        top: 0;
        opacity: 1;
        visibility: visible;
      }
      h3,
      .blog-single-details,
      .blog-single-details i,
      p {
        color: #fff;
      }
      .circle {
        background-color: #fff;
        i {
          color: var(--gradient-green);
        }
      }
    }
  }
}

/****************************************
blog detail
****************************************/
.blog-detail {
  &-thumbnail {
    img {
      width: 100%;
      @include border-radius(2rem);
    }
  }

  &-content {
    margin-top: 9.4rem;
    padding-bottom: 15rem;

    @media (max-width: 991px) {
      margin-top: 5rem;
      padding-bottom: 5rem;
    }

    .blog-single-details {
      margin: 1.2rem 0 0rem 0;
    }

    > p {
      margin-top: 3rem;
    }

    h3 {
      margin-top: 2.3rem;
    }

    .social {
      margin-top: 3.1rem;
      ul {
        margin-top: 1.5rem;
        li {
          list-style: none;
          display: inline-block;

          i {
            @include animateIt;
          }

          &:not(:last-child) {
            margin-right: 1.9rem;
          }

          a {
            font-size: 3rem;
          }

          &:hover {
            i {
              color: $font-dark;
            }
          }
        }
      }
    }
  }

  &-1 {
    .blog-detail-content {
      padding-bottom: 0;
    }
  }
}

/****************************************
related posts
****************************************/
.related-posts {
  &-wrapper {
    position: relative;
    padding-top: 9.8rem;
  }

  &-inner {
    position: relative;
    z-index: 2;
  }

  &-slider {
    margin-top: 3.8rem;
    &-navigation {
      i {
        color: rgba(255, 255, 255, 0.4);
        &:hover {
          color: #ffff;
        }
      }
    }

    &-container {
      @media (max-width: 991px) {
        max-width: 100%;
        padding: 0;
      }
    }

    .blog-single {
      margin-bottom: 5rem;
    }
  }

  .background-pattern {
    width: 100%;
    height: 74%;
  }
}

/****************************************
comments
****************************************/
.comments {
  &-list {
    padding: 15rem 0 12rem 0;
  }

  &-heading {
    margin-bottom: 1.4rem;
  }

  &-single {
    padding-top: 4rem;
    &-wrapper {
      display: flex;
      position: relative;
    }

    &-content {
      padding-bottom: 3.4rem;
    }

    &.has-reply,
    &:not(:last-child) {
      .comments-single-content {
        border-bottom: 0.1rem solid $color-grey-2;
      }
    }

    h5 {
      color: $font-dark;
      font-weight: 700;
    }

    &-image {
      margin-right: 3.5rem;
    }

    .date {
      font-size: 1.2rem;
      font-weight: 500;
      color: $font-grey-1;
    }

    .comment {
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 3rem;
      color: $font-grey-1;

      margin-top: 0.8rem;
    }

    .reply-button {
      font-size: 1.4rem;
      color: $font-grey-1;

      position: absolute;
      top: 0;
      right: 0;

      span {
        &::before {
          background-color: $font-grey-1;
        }
      }
    }

    & .comments-single {
      padding-left: 13%;
    }

    @media (max-width: 767px) {
      & .comments-single {
        padding-left: 5%;
      }
    }

    @media (max-width: 575px) {
      &-wrapper {
        flex-direction: column;
      }

      &-content {
        margin-top: 3rem;
      }
    }
  }

  @media (max-width: 991px) {
    &-list {
      padding: 5rem 0;
    }
  }
}

/****************************************
comment form
****************************************/
.comments-form {
  &-wrapper {
    padding-bottom: 15rem;
  }

  &-heading {
    margin-bottom: 5.9rem;
  }

  .button {
    display: block;
    margin: 5rem auto 0 auto;
  }

  @media (max-width: 991px) {
    &-wrapper {
      padding-bottom: 5rem;
    }

    &-heading {
      margin-bottom: 3rem;
    }

    .textarea-form {
      margin-top: 2.3rem;
      height: 20rem;
    }
  }
}
